import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import VoteForm from "./VoteForm";
import voteIcon from "../assets/images/VoteIcon.jpg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

function Winners() {
  const [open, setOpen] = useState(false);
  const [selectedVote, setSelectedVote] = useState("");

  const votes = [
    { title: "THE MOST INNOVATIVE FASHION DESIGNER OF THE YEAR", url: "/winner/fashion-designer" },
  ];

  const handleButtonClick = (title) => {
    setSelectedVote(title);
    setOpen(true);
  };

  return (
    <div
      className="column"
      id="votes"
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h4 style={{ color: "#ffffff", marginBottom: "20px" }}>WINNERS</h4>
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "30px",
          }}
        >
          {votes.map((vote, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
              <Link to={vote.url} style={{ textDecoration: 'none' }}>
                <Button
                  component={Item}
                  fullWidth
                  onClick={() => handleButtonClick(vote.title)}
                  sx={{
                    borderRadius: "4px",
                    textTransform: "none", // Prevent automatic capitalization
                    padding: "10px 5px", // Add padding to the button
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={voteIcon}
                    alt="Vote Icon"
                    style={{
                      width: "100%",
                      maxWidth: "100px", // Increased max width for the icon
                      height: "auto",
                      marginBottom: "10px", // Space between icon and text
                    }}
                  />
                  {capitalizeEachWord(vote.title)}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
      <VoteForm open={open} setOpen={setOpen} vote={selectedVote} />
    </div>
  );
}

export default Winners;
