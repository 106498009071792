import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import MusicVideoFemale from "./pages/vote/MusicVideoFemale";
import MusicVideoMale from "./pages/vote/MusicVideoMale";
import RadioStation from "./pages/vote/RadioStation";
import TVStation from "./pages/vote/TVStation";
import CableStation from "./pages/vote/CableStation";
import Blog from "./pages/vote/Blog";
import OnlineMusicPlatform from "./pages/vote/OnlineMusicPlatform";
import Actor from "./pages/vote/Actor";
import Actress from "./pages/vote/Actress";
import EmergingActor from "./pages/vote/EmergingActor";
import EmergingActress from "./pages/vote/EmergingActress";
import TvPersonality from "./pages/vote/TvPersonality";
import SupportiveBrand from "./pages/vote/SupportiveBrand";
import ContentCreator from "./pages/vote/ContentCreator";
import BiggestSong from "./pages/vote/BiggestSong";
import MaleArtist from "./pages/vote/MaleArtist";
import FemaleArtist from "./pages/vote/FemaleArtist";
import BiggestArtistAfrica from "./pages/vote/BiggestArtistAfrica";
import EmergingArtist from "./pages/vote/EmergingArtist";
import MusicProducer from "./pages/vote/MusicProducer";
import StreetSong from "./pages/vote/StreetSong";
import Movie from "./pages/vote/Movie";
import MovieDirector from "./pages/vote/MovieDirector";
import ManOfTheYear from "./pages/vote/ManOfTheYear";
import Humanitarian from "./pages/vote/Humanitarian";
import YoungAchieverMale from "./pages/vote/YoungAchieverMale";
import YoungAchieverFemale from "./pages/vote/YoungAchieverFemale";
import FashionDesigner from "./pages/vote/FashionDesigner";
import WomanOfTheYear from "./pages/vote/WomanOfTheYear";
import Entrepreneur from "./pages/vote/Entrepreneur";
import Podcast from "./pages/vote/Podcast";
import LifetimeAchievement from "./pages/vote/LifetimeAchievement";
import SpecialRecognition from "./pages/vote/SpecialRecognition";
import FashionDesignerWinner from "./pages/winner/FashionDesigner";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route
            exact
            path="/vote/music-video-female-director"
            element={<MusicVideoFemale />}
          />
          <Route
            exact
            path="/vote/music-video-male-director"
            element={<MusicVideoMale/>}
          />
          <Route exact path="/vote/radio-station" element={<RadioStation />} />
          <Route exact path="/vote/tv-station" element={<TVStation/>} />
          <Route exact path="/vote/cable-station" element={<CableStation />} />
          <Route exact path="/vote/blog" element={<Blog />} />
          <Route
            exact
            path="/vote/online-music-platform"
            element={<OnlineMusicPlatform />}
          />
          <Route exact path="/vote/actor" element={<Actor />} />

          <Route exact path="/vote/actress" element={<Actress />} />
          <Route exact path="/vote/emerging-actor" element={<EmergingActor />} />
          <Route
            exact
            path="/vote/emerging-actress"
            element={<EmergingActress />}
          />
          <Route exact path="/vote/tv-personality" element={<TvPersonality />} />
          <Route
            exact
            path="/vote/supportive-brand"
            element={<SupportiveBrand />}
          />
          <Route
            exact
            path="/vote/content-creator"
            element={<ContentCreator />}
          />
          <Route exact path="/vote/biggest-song" element={<BiggestSong />} />
          <Route exact path="/vote/male-artist" element={<MaleArtist />} />
          <Route exact path="/vote/female-artist" element={<FemaleArtist />} />
          <Route
            exact
            path="/vote/biggest-artist-africa"
            element={<BiggestArtistAfrica />}
          />
          <Route
            exact
            path="/vote/emerging-artist"
            element={<EmergingArtist />}
          />
          <Route exact path="/vote/music-producer" element={<MusicProducer />} />
          <Route exact path="/vote/street-song" element={<StreetSong />} />
          <Route exact path="/vote/movie" element={<Movie />} />
          <Route exact path="/vote/movie-director" element={<MovieDirector />} />
          <Route exact path="/vote/man-of-the-year" element={<ManOfTheYear />} />
          <Route exact path="/vote/humanitarian" element={<Humanitarian />} />
          <Route
            exact
            path="/vote/young-achiever-male"
            element={<YoungAchieverMale />}
          />
          <Route
            exact
            path="/vote/young-achiever-female"
            element={<YoungAchieverFemale />}
          />
          <Route
            exact
            path="/vote/fashion-designer"
            element={<FashionDesigner />}
          />
          <Route
            exact
            path="/vote/woman-of-the-year"
            element={<WomanOfTheYear />}
          />
          <Route exact path="/vote/entrepreneur" element={<Entrepreneur />} />
          <Route exact path="/vote/podcast" element={<Podcast />} />
          <Route exact path="/vote/special-recognition" element={<SpecialRecognition />} />
          <Route exact path="/winner/fashion-designer" element={<FashionDesignerWinner />} />
          {/* <Route
            exact
            path="/vote/lifetime-achievement"
            element={<LifetimeAchievement />}
          /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
